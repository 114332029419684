.FeaturesCard {
  @import 'src/styles/theme/main';

  @include media-sm-down {
    width: 100%;
  }

  &__Header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    &__IconWrap {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      margin: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &__Img {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__Title {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-pure-black);
    }
  }

  &__List {
    list-style: none;
    margin: 0;
    padding: 0;

    &__ItemWrap {
      height: 28px;
      display: flex;
      align-items: center;
      column-gap: 4px;

      &__LiText {
        font-size: 12px;
        font-weight: 400;
        color: var(--color-secondary-brown);
        letter-spacing: 0.4px;
      }
    }
  }
}
