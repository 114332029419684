html,
body {
  // Avoid that these styled get overridden by third-party scripts
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  font-family: Roboto, Helvetica, sans-serif;
}

* {
  font-family: Roboto, Helvetica, sans-serif;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  text-align: center;
  margin: 0;
}

.title {
  letter-spacing: 0.11px;
  font-weight: 500;
}

.subtitle {
  letter-spacing: 0.4px;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 10px;

  &-track {
    background: transparent;
    border-radius: 8px;

    &:hover {
      background: #ddd;
    }
  }

  &-thumb {
    background: #aaa;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
  }
}

.react-international-phone-input-container {
  border-bottom: solid 1px #9A918D;
}








