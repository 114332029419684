:root {
  /* Colors */
  --color-primary: #02AB50;
  --color-primary-accent: #eb4e29;
  --color-primary-alpha: rgba(39, 194, 108, 0.07);
  --color-pure-black: #000000;
  --color-secondary-jungleblack: #2c2e3c;
  --color-secondary-charcoal: #585a6e;
  --color-secondary-darkgray: #a4a7b2;
  --color-secondary-lightgray: #edeef2;
  --color-secondary-charcoal-alpha: rgba(88, 90, 110, 0.54) ;
  --color-tertiary-saffron: #f7b82f;
  --color-tertiary-saffron-light: #fce3ac;
  --color-tertiary-blue: #0066FF;
  --color-tertiary-blue-light: #b1b7f0;
  --color-error: #d02839;
  --color-error-light: #f22f42;
  --color-footer-default: #F1EFED4D;
  --color-secondary-brown: #574841;

  --react-international-phone-dial-code-preview-text-color: #000000;
  --react-international-phone-text-color: #000000;
  --react-international-phone-country-selector-background-color: #F1EFED;
  --react-international-phone-dial-code-preview-background-color: #F1EFED;
  --react-international-phone-background-color: #F1EFED;
  --react-international-phone-country-selector-arrow-color: #574841;
  --react-international-phone-border-radius: 4px 4px 0 0;
  --react-international-phone-country-selector-border-color: none;
  --react-international-phone-border-color: none;

  --color-gray-default: #eeeef0;
  --color-gray-border: #d9dbe9;
  --color-gray-background: #f9fafc;

  --color-text-dark: #070417;
  --color-text-light: #6d6f78;

  /* Typography */
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-32: 2rem;
  --font-size-50: 3.125rem;

  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-10: 0.625rem;
  --space-12: 0.75rem;
  --space-16: 1rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-28: 1.75rem;
  --space-32: 2rem;
}

